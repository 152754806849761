var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{style:((_vm.$root.lang == 'ar' ? _vm.merkazi : _vm.poppins) + ' ;height: 100vh;')},[_c('v-app-bar',{staticClass:"px-4",attrs:{"flat":"","fixed":"","color":"transparent","width":"100vw","height":"64px"}},[_c('router-link',{attrs:{"to":'/'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('a',{staticStyle:{"z-index":"100"},attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{attrs:{"src":"media/logos/logo_text.png","contain":"","width":"100px","height":"64px"}})],1)]}}])}),_c('v-spacer'),_c('KTTopbar')],1),_c('v-main',[(!_vm.$vuetify.breakpoint.mobile)?_c('div',{staticClass:"d-flex",staticStyle:{"height":"100vh","width":"100%"}},[_c('div',{staticClass:"d-flex flex-column white",staticStyle:{"height":"inherit","width":"60vw"}},[_c('div',{staticClass:"flex-grow-1 d-flex justify-center px-8 flex-column"},[_c('div',{staticClass:"primary--text text-h2 mb-3"},[_vm._v(" "+_vm._s(_vm.$t("GENERAL.welcome"))+" "),_c('span',{staticClass:"font-weight-black"},[_vm._v(_vm._s(_vm.$t("GENERAL.title")))])]),_c('div',[_c('textra',{staticClass:"primary--text text-h5",attrs:{"data":[
                _vm.$t('GENERAL.features.book', {
                  count: _vm.$doctor.getLocalDocs().length
                }),
                _vm.$t('GENERAL.features.advice'),
                _vm.$t('GENERAL.features.update')
              ],"infinite":true,"timer":5,"filter":"right-left"}})],1)]),(false)?_c('div',[_c('lottie-animation',{staticStyle:{"width":"65%"},style:(_vm.$root.lang === 'ar' ? '' : 'transform: scaleX(-1);'),attrs:{"animationData":require('@/assets/animation/lf20_dy6ooa7u.json'),"loop":true}})],1):_vm._e()]),_c('div',{staticStyle:{"width":"40vw"}},[_c('router-view')],1)]):_vm._e(),(_vm.$vuetify.breakpoint.mobile)?_c('div',{staticStyle:{"width":"100vw"}},[_c('router-view')],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }