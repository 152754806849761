<template>
  <v-app
    class=""
    :style="($root.lang == 'ar' ? merkazi : poppins) + ' ;height: 100vh;'"
  >
    <v-app-bar
      flat
      fixed
      color="transparent"
      class="px-4"
      width="100vw"
      height="64px"
    >
      <router-link :to="'/'" v-slot="{ href, navigate }">
        <a style="z-index:100" :href="href" @click="navigate">
          <v-img
            src="media/logos/logo_text.png"
            contain
            width="100px"
            height="64px"
          />
        </a>
      </router-link>
      <v-spacer />

      <KTTopbar />
    </v-app-bar>

    <v-main>
      <div
        style="height:100vh; width:100%"
        v-if="!$vuetify.breakpoint.mobile"
        class="d-flex"
      >
        <div
          class="d-flex flex-column white"
          style="height:inherit; width:60vw"
        >
          <div class="flex-grow-1 d-flex justify-center px-8 flex-column">
            <div class="primary--text text-h2 mb-3">
              {{ $t("GENERAL.welcome") }}
              <span class="font-weight-black">{{ $t("GENERAL.title") }}</span>
            </div>
            <div>
              <textra
                class="primary--text text-h5"
                :data="[
                  $t('GENERAL.features.book', {
                    count: $doctor.getLocalDocs().length
                  }),
                  $t('GENERAL.features.advice'),
                  $t('GENERAL.features.update')
                ]"
                :infinite="true"
                :timer="5"
                filter="right-left"
              />
            </div>
          </div>
          <div v-if="false">
            <lottie-animation
              :animationData="require('@/assets/animation/lf20_dy6ooa7u.json')"
              :loop="true"
              style="width:65%"
              :style="$root.lang === 'ar' ? '' : 'transform: scaleX(-1);'"
            />
          </div>
        </div>
        <div style="width:40vw">
          <router-view></router-view>
        </div>
      </div>
      <div style="width:100vw" v-if="$vuetify.breakpoint.mobile">
        <router-view></router-view>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import LottieAnimation from "lottie-web-vue";

export default {
  name: "auth",
  components: {
    LottieAnimation,
    KTTopbar: () => import("@/view/layout/header/Topbar")
  },
  data() {
    return {
      poppins: "font-family: 'Poppins' !important;",
      merkazi: "font-family: 'Markazi Text' !important; "
    };
  },
  methods: {},
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    })
  }
};
</script>
<style>
.animate_ar {
  z-index: 0;
  clip-path: circle(45%);
  background-color: #f8d000;
}

.animate_en {
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  transform: scaleX(-1);
  -ms-filter: fliph; /*IE*/
  filter: fliph; /*IE*/
  z-index: 0;
  clip-path: circle(45%);
  background-color: #f8d000;
}
</style>
